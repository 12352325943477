import * as React from "react";
import BaseLayout from "../../components/layouts/baseLayout";

const LessonsHome: React.FunctionComponent = () => (
    <BaseLayout>
        <h1>Lessons</h1>
    </BaseLayout>
);

export default LessonsHome;
